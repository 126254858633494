import React from 'react'
import Auth from '../components/Auth'
import { ActivityIndicator } from '../components/generic'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import theme from '../theme.style'
import { ThemeProvider } from 'emotion-theming'
import { rmaLogo } from '../images'

export const useTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#00bce7",
    secondary: "#fbad17",
  },
  logo: rmaLogo
}


function SignInPage() {
  if (typeof window === 'undefined') {
    return  <ThemeProvider theme={useTheme}>
      <ActivityIndicator />
    </ThemeProvider>
  }

  return <ThemeProvider theme={useTheme}>
    <Layout noHeader noMoku noFooter>
      <SEO title="Salesperson Sign In" />
      <Auth redirect="/sales" azureAuth login />
    </Layout>
  </ThemeProvider>
}

export default SignInPage